// App.jsx
import React, { useEffect, lazy, Suspense } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { motion, useScroll, useSpring } from 'framer-motion';
import ParticlesBackground from './components/ParticlesBackground.jsx';
import NavigationMenu from './components/NavigationMenu.jsx';
import './App.css';

const IntroSection = lazy(() => import('./components/IntroSection.jsx'));
const SkillsSection = lazy(() => import('./components/SkillsSection.jsx'));
const ProjectsSection = lazy(() => import('./components/ProjectsSection.jsx'));
const ContactSection = lazy(() => import('./components/ContactSection.jsx'));

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900">
    <motion.div
      className="h-32 w-32 border-t-2 border-b-2 border-blue-500 rounded-full"
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
    />
  </div>
);

export default function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      mirror: true,
      easing: 'cubic-bezier(0.65, 0, 0.35, 1)'
    });

    const findCurrentSectionIndex = () => {
      const sections = document.querySelectorAll('section');
      let currentIndex = -1;
      let smallestDistance = Infinity;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        const distance = Math.abs(rect.top);

        // If a section is near the top of the viewport
        if (distance < smallestDistance) {
          smallestDistance = distance;
          currentIndex = index;
        }
      });

      return currentIndex;
    };

    const scrollToSection = (index) => {
      const sections = document.querySelectorAll('section');
      if (index >= 0 && index < sections.length) {
        sections[index].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    };

    const handleScroll = () => {
      requestAnimationFrame(() => {
        document.body.style.setProperty('--scroll', window.scrollY / (document.documentElement.scrollHeight - window.innerHeight));
      });
    };

    const handleWheel = (e) => {
      // Only apply snapping on desktop screens
      if (window.innerWidth <= 1200) return;

      e.preventDefault();
      const currentIndex = findCurrentSectionIndex();

      if (currentIndex !== -1) {
        if (e.deltaY > 0) {
          scrollToSection(currentIndex + 1);
        } else {
          scrollToSection(currentIndex - 1);
        }
      }
    };

    const handleKeyDown = (e) => {
      // Only apply snapping on desktop screens
      if (window.innerWidth <= 1200) return;
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        const currentIndex = findCurrentSectionIndex();

        if (currentIndex !== -1) {
          if (e.key === 'ArrowDown') {
            scrollToSection(currentIndex + 1);
          } else {
            scrollToSection(currentIndex - 1);
          }
        }
      }
    };

    let touchStartY = 0;
    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      // Disable touch snapping on mobile
      if (window.innerWidth <= 768) return;

      if (!touchStartY) return;

      const touchEndY = e.touches[0].clientY;
      const delta = touchStartY - touchEndY;

      if (Math.abs(delta) > 50) { // Minimum swipe distance
        const sections = document.querySelectorAll('section');
        let currentSectionIndex = -1;

        sections.forEach((section, index) => {
          const rect = section.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
            currentSectionIndex = index;
          }
        });

        if (currentSectionIndex !== -1) {
          e.preventDefault();
          const nextIndex = delta > 0
            ? Math.min(currentSectionIndex + 1, sections.length - 1)
            : Math.max(currentSectionIndex - 1, 0);

          sections[nextIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
        touchStartY = 0;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('touchstart', handleTouchStart, { passive: true });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="relative min-h-screen bg-gray-900 text-white overflow-hidden">
      <ParticlesBackground />
      <NavigationMenu />
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-primary via-secondary to-accent origin-left z-50"
        style={{ scaleX }}
      />

      <Suspense fallback={<LoadingSpinner />}>
        <div className="fixed inset-0 bg-gradient-to-br from-gray-900 via-gray-900 to-gray-900" />
        <div className="fixed inset-0 bg-[radial-gradient(circle_at_center,rgba(96,165,250,0.1),transparent_50%)]" />

        <div className="relative z-10 mx-auto bg-[var(--background)]">
          <section id="intro-section"><IntroSection /></section>
          <section id="skills-section"><SkillsSection /></section>
          <section id="projects-section"><ProjectsSection /></section>
          <section id="contact-section"><ContactSection /></section>
        </div>
      </Suspense>
    </div>
  );
}
